import React from "react"
import { detect } from "@lingui/detect-locale"

export default function NotFound() {
  if (typeof window !== "undefined") {
    const result = detect(window.navigator.language)

    if (result.includes("fr")) {
      window.location = `/fr`
    } else if (result.includes("ru")) {
      window.location = `/`
    } else {
      window.location = `/en`
    }
  }

  return null
}
